import axios from 'axios';
import { API_BASE_URL } from '@/config';

// 省市区列表数据的请求路径
const cityURL = 'https://yjy-oss-files.oss-cn-zhangjiakou.aliyuncs.com/tuxian/area.json';

// 函数来获取URL中的token参数
const getTokenFromUrl = () => {
    const queryParams = new URLSearchParams(window.location.search);
    return queryParams.get('token');
};

// 获取省市区列表数据
export const getCityList = async () => {
    if (window.cityList) {
        return window.cityList;
    }
    try {
        const response = await axios.get(cityURL);
        if (response.data) {
            window.cityList = response.data; // 缓存数据到window对象
            return response.data;
        }
    } catch (error) {
        console.error('获取省市区数据失败:', error);
        return [];
    }
};

// 获取商品列表
export const listitem = async () => {
    try {
        const token = getTokenFromUrl();
        const response = await axios.get(`${API_BASE_URL}/imt/imtitem`, {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        });
        return response.data.status === 200 ? response.data.data : [];
    } catch (error) {
        console.error('获取商品列表失败:', error);
        return [];
    }
};

// 获取商店列表
export const listshop = async (city, district) => {
    try {
        const token = getTokenFromUrl();
        const response = await axios.post(`${API_BASE_URL}/imt/imtshop`, {
            city, district
        }, {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        });
        return response.data.status === 200 ? response.data.data : [];
    } catch (error) {
        console.error('获取商店列表失败:', error);
        return [];
    }
};

// 获取手机列表
export const listphone = async () => {
    try {
        const token = getTokenFromUrl();
        const response = await axios.get(`${API_BASE_URL}/imt/imtphone`, {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        });
        return response.data.status === 200 ? response.data.data : [];
    } catch (error) {
        console.error('获取手机列表失败:', error);
        return [];
    }
};

// 提交IMT信息
export const submitImtInfo = async (phoneNumber, shopId, itemIds) => {
    try {
        const token = getTokenFromUrl();
        const response = await axios.post(`${API_BASE_URL}/imt/imtupdate`, {
            phoneNumber,
            shopId,
            itemIds
        }, {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        });
        return response.data.status === 200 ? response.data.data : [];
    } catch (error) {
        console.error('提交IMT信息失败:', error);
        throw error; // 可以选择重新抛出错误，以便在组件中处理
    }
};

// 发送验证码
export const sendCode = async (phoneNumber) => {
    try {
        const token = getTokenFromUrl();
        const response = await fetch(`${API_BASE_URL}/imt/imtcode`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({ phoneNumber })
        });
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('发送验证码失败:', error);
        throw error;
    }
};

// 登录
export const loginWithPhoneCode = async (phoneNumber, phoneCode) => {
    try {
        const token = getTokenFromUrl();
        const response = await fetch(`${API_BASE_URL}/imt/imtlogin`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({ phoneNumber, phoneCode })
        });
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('登录失败:', error);
        throw error;
    }
};