<template>
  <div class="imt-info">
    <!-- 手机号下拉框 -->
    <div class="row">
      <select v-model="selectedPhone">
        <option value="" disabled>请选择手机号</option>
        <option v-for="phone in phones" :key="phone" :value="phone">{{ phone }}</option>
      </select>
    </div>
    <!-- 第二行：省市区 -->
    <div class="row">
      <!-- 省份下拉列表 -->
      <select v-model="selectedProvince" @change="onProvinceChange">
        <option value="" disabled>请选择省份</option>
        <option v-for="province in provinces" :key="province.code" :value="province.code">{{ province.name }}</option>
      </select>

      <!-- 城市下拉列表 -->
      <select v-model="selectedCity" @change="onCityChange">
        <option value="" disabled>请选择城市</option>
        <option v-for="city in cities" :key="city.code" :value="city.code">{{ city.name }}</option>
      </select>

      <!-- 区域下拉列表 -->
      <select v-model="selectedArea">
        <option value="" disabled>请选择区域</option>
        <option v-for="area in areas" :key="area.code" :value="area.code">{{ area.name }}</option>
      </select>
    </div>

    <!-- 第三行：商店 -->
    <div class="row">
      <!-- 商店单选下拉列表 -->
      <select v-model="selectedShop">
        <option value="" disabled>请选择商店</option>
        <option v-for="shop in formattedShops" :key="shop.id" :value="shop.id">{{ shop.displayName }}</option>
      </select>
    </div>
    <!-- 第四行：商品 -->
    <div class="row">
      <!-- 商品多选下拉列表 -->
      <select v-model="selectedItems" multiple>
        <option v-for="item in items" :key="item.itemCode" :value="item.itemCode">{{ item.title }}</option>
      </select>
    </div>

    <!-- 第四行：提交按钮 -->
    <div class="row submit-container">
      <button @click="submit">提交</button>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, watch, computed } from 'vue';
import { getCityList, listitem, listshop, listphone, submitImtInfo } from '@/api'; // 假设这是从area.json获取数据的函数

export default {
  setup() {
    const selectedPhone = ref(''); // 选中的手机号
    const selectedProvince = ref('');
    const selectedCity = ref('');
    const selectedArea = ref('');
    const selectedProvinceName = ref(''); // 存储选中的省份名称
    const selectedCityName = ref(''); // 存储选中的城市名称
    const selectedAreaName = ref(''); // 存储选中的区域名称
    const selectedItems = ref([]); // 选中的商品
    const selectedShop = ref(''); // 选中的商店
    const provinces = ref([]);
    const cities = ref([]);
    const areas = ref([]);
    const items = ref([]); // 商品列表
    const shops = ref([]); // 商店列表
    const phones = ref([]); // 手机号列表


    // 加载省份数据
    onMounted(async () => {
      const data = await getCityList();
      provinces.value = data || [];
      const itemData = await listitem();  // 加载商品数据
      items.value = itemData || [];
      phones.value = await listphone(); // 加载手机号数据
    });

    // 当省份改变时，更新城市列表
    const onProvinceChange = () => {
      const selected = provinces.value.find(p => p.code === selectedProvince.value);
      cities.value = selected ? selected.areaList : [];
      selectedCity.value = ''; // 重置城市和区域选择
      selectedArea.value = '';
      selectedProvinceName.value = selected ? selected.name : '';
    };

    // 当城市改变时，更新区域列表
    const onCityChange = () => {
      const selected = cities.value.find(c => c.code === selectedCity.value);
      areas.value = selected ? selected.areaList : [];
      selectedArea.value = ''; // 重置区域选择
      selectedCityName.value = selected ? selected.name : '';
    };

    // 当区域改变时，更新选中的区域名称
    watch(selectedArea, async (newAreaCode) => {
      const selected = areas.value.find(a => a.code === newAreaCode);
      selectedAreaName.value = selected ? selected.name : '';

      if (selectedCityName.value && selectedAreaName.value) {
        try {
          const shopData = await listshop(selectedCityName.value, selectedAreaName.value);
          shops.value = shopData;
        } catch (error) {
          console.error('获取商店列表失败:', error);
        }
      }
    });

    // 格式化商店数据，添加displayName属性
    const formattedShops = computed(() => {
      return shops.value.map(shop => ({
        id: shop.id,
        displayName: `${shop.name || shop.tenantName} / ${shop.fullAddress}`
      }));
    });

    // 提交表单的逻辑
    const submit = async () => {
      const phoneNumber = selectedPhone.value;
      const shopId = selectedShop.value;
      const itemIds = selectedItems.value.join('@') + '@'; // 格式化商品ID

      try {
        const response = await submitImtInfo(phoneNumber, shopId, itemIds);

        if (response.status === 200 || response.type != 'options') {
          // 如果状态码为200，则认为提交成功
          alert("提交成功~");
        } else {
          // 如果状态码不是200，显示从响应中获取的错误消息
          alert(response.message || "提交失败");
        }
      } catch (error) {
        console.error('提交失败:', error);
        // 显示一个通用错误消息
        alert("提交失败~");
      }
    };


    return {
      selectedPhone,
      phones,
      selectedShop,
      selectedProvince,
      selectedCity,
      selectedArea,
      selectedItems,
      formattedShops,
      provinces,
      cities,
      areas,
      items,
      shops,
      onProvinceChange,
      onCityChange,
      submit
    };
  }
};
</script>

<style scoped>
.imt-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.row {
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  width: 100%;
}

select {
  margin: 0 5px;
}

.submit-container {
  display: flex;
  justify-content: center;
}

input {
  margin: 10px 0;
  padding: 8px;
}

button {
  cursor: pointer;
  padding: 10px 15px;
  margin-top: 10px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 4px;
}

button:hover {
  background-color: #45a049;
}

button:disabled {
  background-color: #cccccc;
}
</style>
