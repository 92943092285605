<template>
    <div class="welcome-container">
      Hello World !!
    </div>
  </template>
  
  <script>
  export default {
    name: "WelcomePage"
  };
  </script>
  
  <style scoped>
  .welcome-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    font-size: 2em;
    text-align: center;
  }
  </style>
  