<template>
    <div class="entry-point">
        <div class="form-container">
            <input type="text" v-model="phoneNumber" placeholder="输入手机号" />
            <div class="code-container">
                <input type="text" v-model="phoneCode" placeholder="验证码" />
                <button :disabled="isButtonDisabled" @click="sendVerificationCode" class="send-code-button">
                    {{ buttonText }}
                </button>
            </div>
            <button @click="loginPhoneCode">登录</button>
        </div>
    </div>
</template>
  
<script>
import { sendCode, loginWithPhoneCode } from '@/api';
export default {
    data() {
        return {
            phoneNumber: '',
            phoneCode: '',
            token: '',
            isButtonDisabled: false,
            buttonText: '获取验证码',
            countdown: null,
        };
    },
    created() {
        // 从URL获取token
        const queryParams = new URLSearchParams(window.location.search);
        this.token = queryParams.get('token') || '';
    },
    methods: {
        async sendVerificationCode() {
            const phoneRegex = /^1[3-9]\d{9}$/; // 简单的中国手机号码正则表达式
            if (!phoneRegex.test(this.phoneNumber)) {
                alert("请输入有效的手机号码");
                return;
            }
            if (!this.token) {
                alert("缺少认证Token");
                return;
            }
            try {
                // 发送请求
                const response = await sendCode(this.phoneNumber);
                if (response.status === 200) {
                    alert("验证码发送成功");
                    // 启动计时器
                    this.isButtonDisabled = true;
                    this.buttonText = '60秒后重试';
                    let seconds = 60;
                    this.countdown = setInterval(() => {
                        seconds -= 1;
                        this.buttonText = `${seconds}秒后重试`;

                        if (seconds <= 0) {
                            clearInterval(this.countdown);
                            this.buttonText = '获取验证码';
                            this.isButtonDisabled = false;
                        }
                    }, 1000);
                } else {
                    alert(response.message || "验证码发送失败");
                }
            } catch (error) {
                alert("发送请求失败，请稍后再试");
            }

        },
        async loginPhoneCode() {
            // 校验phoneCode是否为空
            if (!this.phoneCode.trim()) {
                alert("验证码不能为空");
                return;
            }
            // 登录逻辑
            try {
                const response = await loginWithPhoneCode(this.phoneNumber, this.phoneCode);
                if (response.status === 200) {
                    alert("登录成功\n 仅新用户需要发送/imtupdateurl进行数据补齐");
                } else {
                    alert(response.message || "登录失败");
                }
            } catch (error) {
                alert("登录请求失败，请稍后再试");
            }
        },
        beforeDestroy() {
            if (this.countdown) {
                clearInterval(this.countdown);
            }
        }
    },
}
</script>
  
<style scoped>
.entry-point {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.code-container {
    position: relative;
}

.send-code-button {
    position: absolute;
    right: 0;
    top: 0;
    border: none;
    background: none;
}

input {
    margin: 10px 0;
    padding: 8px;
}

button {
    cursor: pointer;
    padding: 10px 15px;
    margin-top: 10px;
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 4px;
}

button:hover {
    background-color: #45a049;
}

button:disabled {
    background-color: #cccccc;
}

.send-code-button {
    background: none;
    border: none;
    color: blue;
    /* 按钮默认文字颜色 */
    cursor: pointer;
}

.send-code-button:hover {
    color: #0456c8;
    /* 鼠标悬停时的文字颜色 */
    background-color: transparent;
    /* 确保背景色在悬停时不变 */
}
</style>
  