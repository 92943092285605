import { createRouter, createWebHistory } from 'vue-router';
import ImtLogin from '@/components/ImtLogin.vue';
import ImtInfo from '@/components/ImtInfo.vue';
import Welcome from '@/components/Welcome';

const routes = [
    {
        path: '/',
        name: 'Welcome',
        component: Welcome,
        meta: { title: '欢迎页面' }
    },
    {
        path: '/imtLogin',
        name: 'ImtLogin',
        component: ImtLogin,
        meta: { title: '登录' }
    },
    {
        path: '/imtInfo',
        name: 'ImtInfo',
        component: ImtInfo,
        meta: { title: '信息页面' }
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

export default router;
